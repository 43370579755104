@import url('https://fonts.googleapis.com/css?family=Cutive+Mono|Roboto+Mono|VT323');


a {
    color: #000;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
}

.App {
}

.App-Title {
  position: absolute;
  transform: translateY(35vh);
  padding: 30px;
}

.App-MyName {
  font-size: 40px;
  font-family: 'Roboto Mono', monospace;
}

.App-MyTitle {
  font-size: 38px;
  font-family: 'Cutive Mono', monospace;
}


.App-InfoBar {
    height: 80px;
    transform: translateY(calc(100vh - 80px));
}

.App-LinkBar {
    position: relative;
    display: inline-grid;
    left: 50vw;
    top: 22px;
    /* bottom: 0; */
    grid-template-columns: repeat(3,15em);
    /* -webkit-transform: translateX(50%); */
    /* transform: translateX(50%); */
    margin-left: calc(-360px + 4em);
}

.App-LinkBar-Link {
  font-size: 18px;
  font-family: 'Cutive Mono', monospace;    
}


.App-link {
  color: #61dafb;
}

.App-BlogPost {
    position: fixed;
    /* -webkit-transform: translate(35em,-44px); */
    /* transform: translate(35em,-44px); */
    width: calc(100vw - 468.08px - 225px);
    height: calc(100vh - 100px);
    left: 468.08px;
    /* right: 100px; */
    top: 1vh;
    border-color: #000;
}


.BlogIndex {
    display: grid;
    position: fixed;
    transform: translate(calc(100vw - 200px));
    font-family: Roboto Mono,monospace;
    font-size: 12px;
    float: left;
}

.BlogIndex-Link {
    padding: 10px;
}
